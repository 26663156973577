import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  LinearProgress,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { UpdateFreeResponseResponseDocument } from '../../../../gql/mutations/__generated__/response.generated';
import { AssessmentValueEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { ASSESSMENT_VALUES } from '../../../../utils/assessmentValueMappings';
import { containsImages } from '../../../../utils/quillHelper';
import { CustomTooltip } from '../../../shared/CustomTooltip';
import { Emoji } from '../../../shared/Emoji';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { TextToSpeech } from '../../../shared/TextToSpeech';
import { TranslateText } from '../../../shared/TranslateText';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import { StudySessionContext } from '../../StudySession/context';
import { RatingKey } from './RatingKey';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  answerContainer: {},
  answer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  exemplarContainer: {
    marginTop: theme.spacing(2),
  },
  title: {
    textDecoration: 'underline',
  },
  ratingKeyLabel: {
    textDecoration: 'underline',
    display: 'inline-block',
  },
  aiCheckboxLabel: {
    color: theme.palette.common.white,
    display: 'inline-block',
  },
  instructions: {
    marginBottom: theme.spacing(2),
    display: 'block',
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    paddingTop: theme.spacing(2),
  },
  ratingsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  keyContainer: {
    ...buttonLink(theme),
    padding: 0,
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ratingsButton: {
    [theme.breakpoints.down('md')]: {
      margin: 5,
    },
    borderRadius: 60,
    paddingTop: 10,
    width: 85,
    backgroundColor: theme.palette.mint.light,
    border: `1px solid ${theme.palette.mint.dark}`,
    fontSize: 30,
    '&:hover': {
      backgroundColor: theme.palette.mint.dark,
    },
  },
  autogradedContainer: {
    padding: theme.spacing(1),
  },
  continueButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  continueButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
  },
  lineBreak: {
    color: theme.palette.secondary.main,
  },
}));

type FreeResponseFeedbackProps = {
  question: { __typename?: 'Question' } & QuestionAttributesFragment;
  freeResponseResponseId?: string;
  getNextAssignmentQuestion: () => void;
  isPersonal?: boolean;
  aiAnalysisTrigger: (aiAnalysisCheck: boolean) => void;
  isExperiment: boolean;
};

export function FreeResponseFeedback({
  freeResponseResponseId,
  question,
  getNextAssignmentQuestion,
  isPersonal = false,
  aiAnalysisTrigger,
  isExperiment,
}: FreeResponseFeedbackProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const {
    setFreeResponseWithoutSelfAssesmentId,
    setFeedbackVisible,
    responseMeta,
  } = useContext(StudySessionContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const goNext = () => {
    if (!aiCheckBox) {
      setFeedbackVisible(false);
      setFreeResponseWithoutSelfAssesmentId(undefined);
      getNextAssignmentQuestion();
    } else {
      aiAnalysisTrigger(true);
    }
  };
  const [aiCheckBox, setAiCheckBox] = useState<boolean>(false);
  useEffect(() => {}, [aiCheckBox]);
  const [updateFreeResponseResponse, { loading: updateLoading }] = useMutation(
    UpdateFreeResponseResponseDocument,
    {
      onError: onError(dispatch),
      onCompleted: goNext,
    }
  );

  const openTooltip = () => setTooltipOpen(true);
  const closeTooltip = () => setTooltipOpen(false);

  const selfAssessmentGuide = () => (
    <>
      <Typography variant="caption" className={classes.instructions}>
        Select a rating that best describes how your response relates to the
        provided answer:
      </Typography>
      <CustomTooltip placement="top" open={tooltipOpen} title={<RatingKey />}>
        <button
          onBlur={closeTooltip}
          onClick={openTooltip}
          className={classes.keyContainer}
        >
          <Typography
            className={classes.ratingKeyLabel}
            variant="body1"
            color="secondary"
          >
            View Rating Key
          </Typography>
        </button>
      </CustomTooltip>
    </>
  );
  const hasImages = question && containsImages(question.richText);
  const analyzeButton = () => {
    if (!activeEnrollment?.group?.course?.studentFacingAiEnabled) return null;
    if (hasImages) return null;

    return (
      <div>
        <Checkbox
          color="success"
          onChange={(e) => {
            setAiCheckBox(e.target.checked);
          }}
        />
        <Typography className={classes.aiCheckboxLabel} variant="body1">
          Include Feedback from AI (only available for missed questions)
        </Typography>
      </div>
    );
  };
  const autograded = responseMeta?.autograded;
  const gradingZone = () => {
    if (autograded) {
      return (
        <div className={classes.autogradedContainer}>
          <Typography color="white">
            Your answer is missing required keyword(s). Please study the answer
            above to prepare for your next attempt.
          </Typography>
          <div className={classes.continueButtonContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={goNext}
              className={classes.continueButton}
            >
              Continue
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div onClick={closeTooltip} className={classes.exemplarContainer}>
        {analyzeButton()}
        {updateLoading ? (
          <LinearProgress />
        ) : (
          <div className={classes.ratingsContainer}>
            {ASSESSMENT_VALUES.map(
              ([rating, { emoji: symbol, label: emojiLabel }]) => {
                return (
                  <Button
                    className={classes.ratingsButton}
                    key={`rating-${rating}`}
                    disabled={
                      aiCheckBox &&
                      (rating === AssessmentValueEnum.Four ||
                        rating === AssessmentValueEnum.Five)
                    }
                    onClick={() => {
                      if (!freeResponseResponseId) {
                        return;
                      }
                      updateFreeResponseResponse({
                        variables: {
                          selfAssessment: rating,
                          freeResponseResponseId,
                        },
                        update: () => {
                          if (
                            isPersonal &&
                            (rating === AssessmentValueEnum.Four ||
                              rating === AssessmentValueEnum.Five)
                          ) {
                            dispatch(
                              pushSnack({
                                message:
                                  "You've earned 1 XP for your successful recall effort!",
                                severity: 'success',
                              })
                            );
                          }
                        },
                      });
                    }}
                  >
                    <Emoji symbol={symbol} label={emojiLabel} />
                  </Button>
                );
              }
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.answerContainer}>
        <Typography variant="h4" className={classes.title}>
          Answer:
        </Typography>
        <Typography className={classes.answer} variant="body1">
          <TextToSpeech text={question.freeResponseAnswer?.text || ''} />
          <TranslateText delta={question.freeResponseAnswer?.richText.ops} />
          <QuillDeltaAsHtml
            delta={question.freeResponseAnswer?.richText.ops}
            zoomed
          />
        </Typography>
        {question.supplement && (
          <>
            <Typography variant="h4" className={classes.title}>
              Deeper Learning:
            </Typography>
            <Typography className={classes.answer} variant="body1">
              <TextToSpeech text={question.supplement?.plainText || ''} />
              <TranslateText delta={question.supplement?.richText.ops} />
              <QuillDeltaAsHtml
                delta={question.supplement?.richText.ops}
                zoomed
              />
            </Typography>
          </>
        )}
        {!autograded && selfAssessmentGuide()}
      </div>
      {gradingZone()}
    </div>
  );
}
